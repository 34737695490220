import React from "react"
import get from "lodash/get"
import useCitiesOverview from "../hooks/use-overview"
import BarChart from "./BarChart"
import TrackVisibility from "react-on-screen"

const SkillsBarChart = (props) => {
  const skillsData = get(useCitiesOverview(), "skills.all") || []
  const data = skillsData.map(skill => ({ x: skill.title, y: skill.total }))
  return (
    <TrackVisibility once partialVisibility>
      {({ isVisible }) => isVisible && <BarChart data={data} {...props} />}
    </TrackVisibility>
    
  )
}

export default SkillsBarChart
