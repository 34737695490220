import React, { useState, useCallback } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import map from "lodash/map"
import get from "lodash/get"
import includes from "lodash/includes"
import ReactTooltip from "react-tooltip"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import SummaryCard from "../components/SummaryCard"
import styled from "styled-components"
import TagWithTooltip from "../components/TagWithTooltip"
import QuestionIcon from "../components/styled-components/QuestionIcon"
import SingleBarChart from "../components/SingleBarChart"
import PoliciesBarChart from "../components/PoliciesBarChart"
import SkillsBarChart from "../components/SkillsBarChart"
import ActivityRadarChart from "../components/ActivityRadarChart"
import ResponsiveChart from "../components/ResponsiveChart"
import CityMapImage from "../components/CityMapImage"

import useOverview from "../hooks/use-overview"
import useActivities from "../hooks/use-activities"
import useFundedActivities from "../hooks/use-funded-activities"
import useFundingSources from "../hooks/use-funding-sources"
import usePolicies from "../hooks/use-policies"
import useSkills from "../hooks/use-skills"
import useTerms from "../hooks/use-terms"
import useDefinitions from "../hooks/use-definitions"
import usePercentages from "../hooks/use-percentages"

export const CityPageTemplate = ({
  title,
  slug,
  latitude,
  longitude,
  summary,
  spotlight,
  section_approach,
  section_policies,
  section_staff,
  section_activities,
  section_terms,
  section_funding,
  section_funding_sources,
  section_funded_activities,
  section_measurement,
  section_data_availability,
  options_activities = { items: [], byTitle: {} },
  options_funded_activities = { items: [], byTitle: {} },
  options_funding_sources = { items: [], byTitle: {} },
  options_terms = { items: [], byTitle: {} },
  options_policies = { items: [], byTitle: {} },
  options_skills = { items: [], byTitle: {} },
  definitions = {},
  overview = {},
  percentages = {},
  isPreview = false,
}) => {

  const [summaryLocked, setLockSummary ] = useState(false)

  const displayTitle = get(summary, 'display_name') || title || null
  const lat = latitude || undefined
  const lon = longitude || undefined

  // COMBINED THE LIST OF TAGS FOR EACH CATEGORY, IE LIST AND OTHER
  const allPolicies = [...(get(section_policies, 'priority_policies') || []), ...(get(section_policies, 'priority_policies_other') || [])].filter(item => item && item !== "")
  const allSkills = [...(get(section_staff, 'skills') || []), ...(get(section_staff, 'skills_other') || [])].filter(item => item && item !== "")
  const allTerms = [...(get(section_terms, 'terms_list') || []), ...(get(section_terms, 'terms_list_other') || [])].filter(item => item && item !== "")
  const allActivities = (get(section_activities, 'activities') || []).filter(item => item && item !== "")
  const allFundingSources = (get(section_funding_sources, 'funding_sources_list') || []).filter(item => item && item.source)
  const allFundedActivities = get(section_funded_activities, 'funded_activities') || []

  // GET THE ANSWER TO EXPLICIT STRATEGY QUESTION
  const hasExplicitStrategy = get(section_approach, "explicit_strategy")

  // GET THE ANSWER TO HOW THEY APPROACH INNOVATION QUESTION
  const overallApproach = get(section_approach, "overall_approach") || []

  // GET THE GLOBAL DEFINTION OF INNOVATION STRATEGY
  const strategyDefinition = get(definitions, "innovation_strategy")

  // GET THE DYNAMICALLY GENERATED TEXT FOR WHETHER THEY HAVE AN EXPLICIT STRATEGY THAT INCLUDES THE GLOBAL PERCENTAGE VALUE
  const explicitStrategyText = getExplicitStrategyText(displayTitle, hasExplicitStrategy, strategyDefinition, percentages)

  // GET THE DYNAMICALLY GENERATED TEXT FOR HOW THEY APPROACH INNOVATION THAT INCLUDES THE GLOBAL PERCENTAGE VALUE
  const overallApproachText = getOverallApproachText(displayTitle, overallApproach, percentages)

  // GET THE ANSWER FOR WHETHER THEY HAVE DEDICATED FUNDING
  const hasDedicatedFunds = get(section_funding, "dedicated_funds") || "no_answer"

  // GET THE ADDITIONAL COMMENTS ABOUT DEDICATED FUNDING
  const additionalFundingText = get(section_funding, "text_additional") || ""

  // GET THE DYNAMICALLY GENERATED TEXT ABOUT DEDICATED FUNDING THAT INCLUDES THE GLOBAL PERCENTAGE VALUE
  const dedicatedFundsText = getDedicatedFundsText(displayTitle, hasDedicatedFunds, percentages, additionalFundingText)

  return (
    <CityWrapper isPreview={isPreview}>
      {!isPreview && (
        <section className="page-header">
          <CityMapImage
            latitude={lat}
            longitude={lon}
            zoom={5}
          />
        </section>
      )}
      <section className="city-detail" tabIndex="0">
        <div className="container">
          <div className="columns no-gutter">
            <div className="column column-4 summary-column">
              <div className={`summary-card-container ${summaryLocked ? "locked" : ""}`}>
                <SummaryCard
                  slug={slug}
                  title={displayTitle}
                  {...summary}
                  ref={useCallback(node => {
                    if (typeof window !== "undefined" && node) {
                      const isBigger = node.getBoundingClientRect().height + 75 > window.innerHeight
                      isBigger && setLockSummary(true)
                    }
                  }, [])}
                />
              </div>
            </div>
            <div className="column column-7 content-column">
              <div className="city-detail__content">

                {/* SPOTLIGHT */}
                {spotlight && spotlight !== "" && (
                  <section className="city-detail-section spotlight" tabIndex="0">
                    <div className="content">
                      <h2>
                        Spotlight on innovation in {displayTitle}
                      </h2>
                      <div className="large">
                        {spotlight && <ReactMarkdown source={spotlight} />}
                      </div>
                    </div>
                  </section>
                )}

                {/* APPROACH */}
                <section className="city-detail-section approach" tabIndex="0">
                  <div className="content">
                    <h2>Vision and approach to innovation capacity</h2>
                    <div className="large">
                      {section_approach && (
                        <>
                          <p>
                            {explicitStrategyText}
                            {` ${overallApproachText}`}
                          </p>
                          <ReactMarkdown source={section_approach.text_additional} />
                        </>
                      )}
                    </div>
                    {strategyDefinition && (
                      <ReactTooltip type="light" id="definition-innovation-strategy">
                        {strategyDefinition}
                      </ReactTooltip>
                    )}
                    <div className="card">
                      {displayTitle ? (
                        <h3>
                          Policy areas that {displayTitle} is focused <span style={{ whiteSpace: "nowrap" }}>on
                        {getDefinitionTooltip("policy_areas", definitions)}</span>
                        </h3>
                      ) : (
                        <h3>Policy areas</h3>
                      )}
                      
                      <>
                        {allPolicies.length > 0 ? (
                          <div className="tag-list">
                            {allPolicies.map((policy, i) => policy === "" ? null : (
                              <TagWithTooltip
                                key={`policy-tag-${i}`}
                                id={`policy-tag-${i}`}
                                className="purple"
                                description={get(options_policies, `byTitle[${policy}].description`)}
                                examples={get(options_policies, `byTitle[${policy}].examples`)}
                                isPreview={isPreview}
                              >
                                {get(options_policies, `byTitle[${policy}].display_name`) || policy}
                              </TagWithTooltip>
                            ))}
                          </div>
                        ) : <p>{get(section_policies, "text_additional") || `${displayTitle || "The city"} does not prioritise policy sectors for its innovation work.`}</p>}
                        {allPolicies.length > 0 && get(section_policies, "text_additional") && <ReactMarkdown source={get(section_policies, "text_additional")} />}
                        {isPreview ? <div className="chart-placeholder" /> : (
                          <div className="chart-container">
                            <ResponsiveChart initialWidth={590} initialHeight={280} delay={500} mobileAspectRatio={0.78}>
                              {({ width, height }) => (
                                <PoliciesBarChart
                                  width={width}
                                  height={height}
                                  mode={'dark'}
                                  selectedItems={allPolicies}
                                  barWidth={12}
                                  highlightColor={"purple"}
                                />
                              )}
                            </ResponsiveChart>
                            <div className="chart-container__legend">
                              Policy areas by number of cities
                              </div>
                          </div>
                        )}
                      </>
                    </div>
                    <div className="card">
                      <h3>
                        {allSkills.length > 0 ? (
                          `${displayTitle || "The city"} utilizes ${allSkills.length} ${allSkills.length > 1 ? "different " : ""} innovation skill${allSkills.length > 1 ? "s" : ""} or role${allSkills.length > 1 ? "s" : ""}`
                        ) : "Innovation skills or roles"}
                        {getDefinitionTooltip("innovation_roles", definitions)}
                      </h3>
                      {section_staff && (
                        <>
                          {allSkills.length > 0 && (
                            <div className="tag-list">
                              {allSkills.map((skill, i) => skill === "" ? null : (
                                <TagWithTooltip
                                  key={`skill-tag-${i}`}
                                  id={`skill-tag-${i}`}
                                  className="green-blue"
                                  description={get(options_skills, `byTitle[${skill}].description`)}
                                  examples={get(options_skills, `byTitle[${skill}].examples`)}

                                  isPreview={isPreview}
                                >
                                  {get(options_skills, `byTitle[${skill}].display_name`) || skill}
                                </TagWithTooltip>
                              ))}
                            </div>
                          )}
                          <ReactMarkdown source={section_staff.text} />
                          {isPreview ? <div className="chart-placeholder" /> : (
                            <div className="chart-container">
                              <ResponsiveChart initialWidth={590} initialHeight={280} delay={500} mobileAspectRatio={0.78}>
                                {({ width, height }) => (
                                  <SkillsBarChart
                                    width={width}
                                    height={height}
                                    mode={'dark'}
                                    selectedItems={allSkills}
                                    barWidth={12}
                                    highlightColor={"greenBlue"}
                                  />
                                )}
                              </ResponsiveChart>
                              <div className="chart-container__legend">
                                Innovation roles by number of cities
                              </div>
                            </div>
                          )}
                          <ReactMarkdown source={section_staff.text_additional} />
                        </>
                      )}
                    </div>
                  </div>
                </section>

                {/* STRATEGY */}
                <section className="city-detail-section strategy" tabIndex="0">
                  <div className="content">
                    <div className="card">
                      {/* TERMS */}
                      <h3>
                        Terms {displayTitle || ""} most associates with <span style={{whiteSpace: "nowrap"}}>innovation
                        {getDefinitionTooltip("innovation_terms", definitions)}</span>
                      </h3>
                      {section_terms && (
                        <>
                          {allTerms.length > 0 && (
                            <div className="tag-list">
                              {allTerms.map((term, i) => term === "" ? null : (
                                <TagWithTooltip
                                  key={`top-term-${i}`}
                                  id={`top-term-${i}`}
                                  className="dark-blue"
                                  description={get(options_terms, `byTitle[${term}].description`)}
                                  isPreview={isPreview}
                                >
                                  {get(options_terms, `byTitle[${term}].display_name`) || term}
                                </TagWithTooltip>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                      {displayTitle ? (
                        <h3>
                          {`${displayTitle}${displayTitle.endsWith('s') ? "'" : "'s"} most common innovation `}<span style={{ whiteSpace: "nowrap" }}>activities
                          {getDefinitionTooltip("innovation_activities", definitions)}</span>
                        </h3>
                      ) : (
                        <h3>
                         Most common innovation activities
                        </h3>
                      )}
                      
                      {section_activities && (
                        <>
                          {allActivities.length > 0 && (
                            <div className="tag-list">
                              {allActivities.map((item, i) => item === "" ? null : (
                                <TagWithTooltip
                                  key={`activity-tag-${i}`}
                                  id={`activity-tag-${i}`}
                                  className="dark-blue"
                                  description={get(options_activities, `byTitle[${item}].description`)}
                                  examples={get(options_activities, `byTitle[${item}].examples`)}
                                  isPreview={isPreview}
                                >
                                  {get(options_activities, `byTitle[${item}].display_name`) || item}
                                </TagWithTooltip>
                              ))}
                            </div>
                          )}
                          {isPreview ? <div className="chart-placeholder" /> : (
                            <div className = "chart-container chart-container--activities">
                              <ActivityRadarChart
                                mode="dark"
                                cityId={slug}
                                cityName={displayTitle}
                                outerRadius={"77.5%"}
                                showAverageTooltips={false}
                              />
                            </div>
                          )}
                          <ReactMarkdown source={section_activities.text_additional} />
                        </>
                      )}
                    </div>
                  </div>
                </section>


                {/* FUNDING */}
                <section className="city-detail-section funding" tabIndex="0">
                  <div className="content">
                    <h2>How is innovation funded here?</h2>
                    <div className="large">
                      {section_funding && (
                        <>
                          <ReactMarkdown source={dedicatedFundsText} />
                        </>
                      )}
                    </div>

                    {/* FUNDING - SOURCES */}
                    <div className="card">
                      <h3>Top sources of funding</h3>
                      {section_funding_sources && (
                        <>
                          {allFundingSources.length > 0 ? (
                            <div className="tag-list">
                              {allFundingSources.map((item, i) => item === "" ? null : (
                                <TagWithTooltip
                                  key={`funding-source-${i}`}
                                  id={`funding-source-${i}`}
                                  className="light-blue"
                                  description={get(options_funding_sources, `byTitle[${item.source}].description`)}
                                  examples={get(options_funding_sources, `byTitle[${item.source}].examples`)}
                                  comment={get(item, "comment")}
                                  isPreview={isPreview}
                                >
                                  {get(options_funding_sources, `byTitle[${item.source}].display_name`) || item.source}
                                </TagWithTooltip>
                              ))}
                            </div>
                          ) : <p>{get(section_funding_sources, "text_additional") || `${displayTitle || "The city"} has no reported sources of funding.`}</p>}
                        </>
                      )}

                      {/* FUNDING - ACTIVITIES */}
                      <h3>Activities being funded</h3>
                      {section_funded_activities && (
                        <>
                          {allFundedActivities.length > 0 ? (
                            <div className="tag-list">
                              {allFundedActivities.map((item, i) => item === "" ? null : (
                                <TagWithTooltip
                                  key={`funded-activity-tag-${i}`}
                                  id={`funded-activity-tag-${i}`}
                                  className="light-blue"
                                  description={get(options_funded_activities, `byTitle[${item}].description`)}
                                  examples={get(options_funded_activities, `byTitle[${item}].examples`)}
                                  isPreview={isPreview}
                                >
                                  {get(options_funded_activities, `byTitle[${item}].display_name`) || item}
                                </TagWithTooltip>
                              ))}
                            </div>
                          ) : <p>{get(section_funded_activities, "text") || `${displayTitle || "The city"} does not fund any specific activities.`}</p>}
                          {isPreview ? <div className="chart-placeholder" style={{ maxHeight: 60 }} /> : (
                            <div className="funded-activities-chart">
                              {map(options_funded_activities.items, (item, i) => {
                                const count = get(options_funded_activities, `percentages[${item.title}].total`)
                                return (
                                  <div
                                    key={`funded-activity-${i}`}
                                    className={`funded-activities-chart__item ${includes(allFundedActivities, item.title) ? 'active' : ""}`}
                                    style={{ flexBasis: `${get(options_funded_activities, `percentages[${item.title}].percent`) || 0}%` }}
                                    data-tip
                                    data-for={`funded-activity-${i}-tooltip`}
                                  >
                                    <ReactTooltip
                                      type="light"
                                      id={`funded-activity-${i}-tooltip`}
                                      className="funded-activity"
                                    >
                                      <div className="title">
                                        {count && (
                                          <div>
                                            {count} cities
                                        </div>
                                        )}
                                      </div>
                                      <div className="text">
                                        {item.display_name || item.title}
                                      </div>
                                    </ReactTooltip>
                                  </div>
                                )
                              })}
                            </div>
                          )}
                          <ReactMarkdown source={section_funded_activities.text_additional} />
                        </>
                      )}
                    </div> {/* end Card */}
                  </div>
                </section>
                <section className="city-detail-section outcomes" tabIndex="0">
                  <div className="content">
                    <h2>How is innovation measured?</h2>
                    <div className="large">
                      {section_measurement && (
                        <>
                          <ReactMarkdown source={section_measurement.text} />
                        </>
                      )}
                    </div>
                    <div className="card">
                      <h3>
                        Data <span style={{whiteSpace: "nowrap"}}>availability by policy area
                        {getDefinitionTooltip("data_availability", definitions)}</span>
                      </h3>
                      {section_data_availability && (
                        <>
                          {section_data_availability.policy_data && (
                            <>
                              <SingleBarChart
                                className={'outcomes'}
                                items={[
                                  {
                                    id: 'sufficient',
                                    name: 'Sufficient data',
                                    color: 'green',
                                    data: get(section_data_availability, 'policy_data.sufficient') || []
                                  },
                                  {
                                    id: 'insufficient',
                                    name: 'Insufficient data',
                                    color: 'red',
                                    data: get(section_data_availability, 'policy_data.insufficient') || []
                                  },
                                  {
                                    id: 'noresponse',
                                    name: 'No Response',
                                    color: 'grey',
                                    data: get(section_data_availability, 'policy_data.noresponse') || []
                                  }
                                ]}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CityWrapper>
  )
}

const CityPage = ({ data: { markdownRemark: { frontmatter } } }) => {
  const options_activities = useActivities()
  const options_funded_activities = useFundedActivities()
  const options_funding_sources = useFundingSources()
  const options_policies = usePolicies()
  const options_skills = useSkills()
  const options_terms= useTerms()
  const definitions = useDefinitions()
  const overview = useOverview()
  const percentages = usePercentages()
  return (
    <Layout className="city">
      <SEO title={frontmatter.title} />
      <CityPageTemplate
        {...frontmatter}
        definitions={definitions}
        options_activities={options_activities}
        options_funded_activities={options_funded_activities}
        options_funding_sources={options_funding_sources}
        options_policies={options_policies}
        options_skills={options_skills}
        options_terms={options_terms}
        overview={overview}
        percentages={percentages}
      />
    </Layout>
  )
}

const CityWrapper = styled.div`

  .page-header {
    position: relative;
    height: 20rem;
    background: #a0dedf;
    z-index: 1;

    .map-container {
      z-index: 1;
      pointer-events: none;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      height: 28rem;
    }
  }

  .summary-card-container {
    z-index: 100;

    @media (min-width: ${({ theme }) => theme.tablet}) {
      position: sticky;
      top: 90px;
      margin-top: ${({ isPreview }) => isPreview ? "0px" : "-28rem" };
    }

    @media (min-width: ${({ theme }) => theme.desktop}) {
      margin-right: 1.25rem;
    }

    &.locked {
      position: static;
    }
  }

  .city-detail {
    position: relative;
    z-index: 2;
    padding: 0;
    background: ${({ theme }) => theme.offWhite};

    @media (min-width: ${({ theme }) => theme.mobile}) {
      padding: 3rem 0 5rem;
    }

    &__content {
      padding: 1rem 0 0;

      @media (min-width: ${({ theme }) => theme.tablet}) {
        padding: 0 0 0 1.25rem;
      }

      @media (min-width: ${({ theme }) => theme.largeDesktop}) {
        padding-left: 2.5rem;
      }
    }
  }

  .city-detail-section {
    margin-bottom: 1.75rem;
    outline: 0;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: ${({ theme }) => theme.mobile}) {
      margin-bottom: 3rem;

      &.approach {
        margin-bottom: 1.25rem;
      }
    }
  }

  .card {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    @media (min-width: ${({ theme }) => theme.largeDesktop}) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  .tag-list {
    margin: 1.25rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .content {

    h2 {
      margin-bottom: 0.625rem;
    }

    h3 {
      margin-bottom: 1.25rem;
    }

    h2 + p, h3 + p {
      margin-top: 0;
    }

    p {
      padding-right: 1.25rem;

      a {
        &:hover {
          color: black;
        }
      }
    }

    .card p {
      padding-right: 0;
    }

  }

  .chart-container {
    margin-bottom: 2rem;

    &--activities {

      @media (min-width: ${({ theme }) => theme.tablet}) {
        margin-left: -1.25rem;
      }

      .recharts-wrapper {
        margin: 0 auto;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__legend {
      font-family: ${({ theme }) => theme.fonts['roboto'] };
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 1.33;
      letter-spacing: 0.3px;
      color: ${({ theme }) => theme.grey1 };
      margin: 0.75rem 0 0 1.75rem;
      user-select: none;
    }
  }

  .funded-activities-chart {
    width: 100%;
    height: 3.5rem;
    border-radius: 1.75rem;
    background: rgba(90, 136, 159, 0.5);
    display: flex;
    overflow: hidden;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      border-right: 1.5px solid white;
      border-left: 1.5px solid white;
      text-align: center;
      color: transparent;
      font-family: ${({ theme }) => theme.fonts['roboto']};
      letter-spacing: 0.5px;
      font-weight: bold;
      font-size: 0.75rem;
      padding: 0 0.75rem;
      user-select: none;
      cursor: pointer;

      &:last-child {
        padding-left: 0.5rem;
      }

      &:hover {
        color: white;
      }

      &.red {
        background: ${({ theme }) => theme.graphRed};
      }

      &.green {
        background: ${({ theme }) => theme.graphGreen};
      }

      &.grey {
        background: ${({ theme }) => theme.grey1};
      }

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &.active {
        background: ${({ theme }) => theme.lightBlue};
        border-width: 1px;
        color: white;
      }
    }
  }

  .__react_component_tooltip {
    max-width: 70vw;
    border-radius: 12px;
    background: white;
    opacity: 1;
    box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.3);
    text-align: left;
    padding: 1.5rem 1.5rem 1.25rem;

    @media (min-width: ${({ theme }) => theme.tablet}) {
      width: calc(75em / 12 * 7);
    }

    &.funded-activity {
      width: auto;
    }

    .title, h2, h3, h4 {
      font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 0.34px;
      color: ${({ theme }) => theme.lightBlue};
      margin-bottom: 0.625rem;
    }

    .text, p {
      font-family: ${({ theme }) => theme.fonts['vollkorn']};
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.43;
      letter-spacing: -0.09px;
      color: ${({ theme }) => theme.grey1};
      margin-bottom: 0.625rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        templateKey
        slug
        latitude
        longitude
        summary {
          display_name
          country_name
          mayors
          alt_mayor_title
          officers
          population
          census_year
          websites
          city_link
          download_link
          download_filename
          benefits
          benefits_other
          successes
          successes_other
        }
        spotlight
        section_approach {
          text
          explicit_strategy
          overall_approach
          text_additional
        }
        section_policies {
          priority_policies
          priority_policies_other
          text_additional
        }
        section_staff {
          text
          skills
          skills_other
          text_additional
        }
        section_terms {
          terms_list
          terms_list_other
        }
        section_activities{
          activities
          text_additional
        }
        section_funding{
          text
          dedicated_funds
          text_additional
        }
        section_funding_sources{
          funding_sources_list {
            source
            comment
          }
          text_additional
        }
        section_funded_activities {
          funded_activities
          text
          text_additional
        }
        section_measurement {
          text
        }
        section_data_availability {
          policy_data {
            sufficient
            insufficient
            noresponse
          }
        }
      }
    }
  }
`

const getExplicitStrategyText = (displayTitle, hasExplicitStrategy, definition, percentages) => {
  if (!hasExplicitStrategy || hasExplicitStrategy === "no_answer") return null

  const percentage = hasExplicitStrategy === "yes"
    ? get(percentages, "explicit_strategy.has")
    : get(percentages, "explicit_strategy.does_not_have")

  const percentageText = `Along with ${percentage || "{PERCENT}"} of cities surveyed, `

  return (
    <>
      <span>
        {`${percentageText}${displayTitle} ${hasExplicitStrategy === "yes" ? "has" : "does not have"} an explicit `}
        {definition ? (
          <span data-tip data-for="definition-innovation-strategy" style={{ cursor: "pointer", display: "inline" }}>
            <span style={{ textDecoration: "underline" }}>innovation strategy</span>
            <span>&nbsp;<QuestionIcon style={{ marginLeft: 0 }} /></span>
          </span>
        ) : <span>innovation strategy</span>}
        <span>.</span>
      </span>
    </>
  )
}

const getOverallApproachText = (displayTitle, overall_approach, percentages) => {
  let percentage;
  const percentageText = (percentage) => `Similar to ${percentage || "{PERCENT}"} of cities surveyed, `
  if (includes(overall_approach, "holistic") && includes(overall_approach, "specific")) {
    percentage = get(percentages, "approach['both']")
    return `${percentageText(percentage)}${displayTitle} approaches innovation capacity both from a holistic/macro level, as well as in specific policy areas.`
  } else if (includes(overall_approach, "specific")) {
    percentage = get(percentages, "approach['specific']")
    return `${percentageText(percentage)}${displayTitle} approaches innovation capacity in specific policy areas/domains.`
  } else if (includes(overall_approach, "holistic")) {
    percentage = get(percentages, "approach['holistic']")
    return `${percentageText(percentage)}${displayTitle} approaches innovation capacity from a holistic/macro level.`
  } else {
    return ""
  }
}

const getDedicatedFundsText = (displayTitle, hasFunds, percentages, additionalText) => {
  if (!hasFunds || hasFunds === "no_answer") return additionalText
  const percentage = hasFunds === "yes"
    ? get(percentages, "dedicated_funds.has")
    : get(percentages, "dedicated_funds.does_not_have")

  const percentageText = `Like ${percentage || "{PERCENT}"} of cities surveyed, `

  return `${percentageText}${displayTitle} ${hasFunds === "yes" ? "has" : "does not have"} dedicated funding to support innovation capacity. ${additionalText}`
}

const getDefinitionTooltip = (id, definitions) => get(definitions, id) ? (
  <span>
    <QuestionIcon data-tip data-for={`definition-${id}`} />
    <ReactTooltip
      type="light"
      id={`definition-${id}`}
      getContent={() => <ReactMarkdown source={get(definitions, id) || ""} />}
    />
  </span>
) : null

export default CityPage
