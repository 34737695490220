import React from "react"
import styled from "styled-components"
import get from "lodash/get"
import sum from "lodash/sum"

const SingleBarChart = ({
  items = [],
  className = ''
}) => {
  const total = sum(items.map(item => get(item, 'data.length') || 0))
  if (total === 0) return null
  return (
    <Wrapper>
      <div className={`single-bar-chart ${className}`}>
        {items.map(({ id, color, data }) => {
          const count = get(data, 'length') || 0
          if (count === 0) return null
          const percent = Math.ceil((count / total) * 100)
          return (
            <div
              key={`bar-chart-${id}`}
              className={`single-bar-chart__item ${color} ${count === 1 && 'single'}`}
              style={{ flex: `0 0 ${percent}%` }}
            >
              {count}
            </div>
          )
        })}
      </div>
      <div className="single-bar-chart-legend">
        {items.map(({ id, name, color, data = [] }) => data.length === 0 ? null : (
          <div
            key={`bar-chart-legend-${id}`}
            className={`single-bar-chart-legend__item ${color}`}
          >
            <h4>{name}</h4>
            {data.map((datum, i) => (
              <p key={`${id}-${i}`}>{datum}</p>
            ))}
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.single-bar-chart {
  width: 100%;
  height: 3.5rem;
  border-radius: 1.75rem;
  background: rgba(90, 136, 159, 0.5);
  display: flex;
  overflow: hidden;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    border-right: 1.5px solid white;
    border-left: 1.5px solid white;
    text-align: center;
    color: white;
    font-family: ${({ theme }) => theme.fonts['roboto']};
    letter-spacing: 0.5px;
    font-weight: bold;
    font-size: 0.875rem;
    
    &.red {
      background: ${({ theme }) => theme.graphRed};
    }

    &.green {
      background: ${({ theme }) => theme.graphGreen};
    }

    &.grey {
      background: ${({ theme }) => theme.grey1};
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    &.active {
      background: ${({ theme }) => theme.lightBlue};
      border-color: ${({ theme }) => theme.lightBlue};
    }
  }

  &.outcomes {
    .single-bar-chart__item {
      color: white;
    }
    
    & > div {
      font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
      text-align: left;
      padding-left: 0.75rem;
      font-size: 1.5rem;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        padding-left: 1rem;
      }

      @media (min-width: ${({ theme }) => theme.tablet}) {
        padding-left: 1.25rem;
        font-size: 2rem;
      }

      &.single {
        padding-left: 0;
        text-align: center;
      }
    }
  }
}

.single-bar-chart-legend {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2.5rem 0 0;

  @media (min-width: ${({ theme }) => theme.desktop}) {
    flex-direction: row;
  }

  &__item {
    position: relative;
    flex: 1;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    white-space: nowrap;

    &:before {
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      background: ${({ theme }) => theme.grey1};
      position: absolute;
      top: 0.45rem;
      left: -1.0rem;
      border-radius: 4.5px;
    }

    &.red {
      &:before {
        background: ${({ theme }) => theme.graphRed};
      }
    }

    &.green {
      &:before {
        background: ${({ theme }) => theme.graphGreen};
      }
    }
  }

  h4, p {
    margin: 0;
  }

  p {
    line-height: 2;
    font-size: 0.75rem;
  }
}
`

export default SingleBarChart