import React from "react"
import styled from "styled-components"
import LocationPin from "./styled-components/LocationPin"
import { getStaticMapImageUrl } from "../utils"

const CityMapImage = ({
	latitude = 0,
	longitude = 0,
	zoom = 0,
	width = 1280,
	height = 450
}) => {
	const mapImageUrl = getStaticMapImageUrl({ latitude, longitude, zoom, width, height })
	return (
		<Wrapper
			style={{
				backgroundImage: `url(${mapImageUrl})`
			}}
		>
			<LocationPin className="pin no-cursor"/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent no-repeat center center;
	background-size: cover;

	.pin {
		position: absolute;
		top: calc(50% - 36px);
		left: 50%;
		transform: translate(-50%, 0);
	}

`

export default CityMapImage
