import { graphql, useStaticQuery } from 'gatsby'
import keyBy from "lodash/keyBy"

const useTerms = () => {
  const data = useStaticQuery(graphql`
    query {
      terms: allMarkdownRemark(
        filter: { 
          frontmatter: {
            tag_type: { 
              eq: "term"
            }
          }
        }
      ) {
        nodes {
          id
          frontmatter {
            title
            display_name
            description
          }
        }
      }
    }
  `)

  const terms_list = data.terms.nodes.map(term => ({
    id: term.id,
    ...term.frontmatter,
  }))

  return {
    items: terms_list,
    byTitle: keyBy(terms_list, "title")
  }
}

export default useTerms
