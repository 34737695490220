import { graphql, useStaticQuery } from 'gatsby'
import keyBy from "lodash/keyBy"

const useActivities = () => {
  const data = useStaticQuery(graphql`
    query {
      activities: allMarkdownRemark(
        filter: { 
          frontmatter: {
            tag_type: { 
              eq: "activity"
            }
          }
        }
      ) {
        nodes {
          id
          frontmatter {
            title
            display_name
            description
            examples
          }
        }
      }
    }
  `)

  const activities_list = data.activities.nodes.map(activity => ({
    id: activity.id,
    ...activity.frontmatter,
  }))

  return {
    items: activities_list,
    byTitle: keyBy(activities_list, "title")
  }
}

export default useActivities
