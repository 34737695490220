import { graphql, useStaticQuery } from "gatsby"
import get from "lodash/get"

const useDefinitions = () => {
  const data = useStaticQuery(graphql`
    query {
      definitions: markdownRemark(frontmatter: { slug: { eq: "city-page-definitions-data"}}){
        frontmatter {
					definition_innovation_strategy
					definition_policy_areas
					definition_innovation_roles
					definition_innovation_terms
					definition_innovation_activities
					definition_data_availability
        }
      }
    }
  `);
	const {
		definition_innovation_strategy: innovation_strategy,
		definition_policy_areas: policy_areas,
		definition_innovation_roles: innovation_roles,
		definition_innovation_terms: innovation_terms,
		definition_innovation_activities: innovation_activities,
		definition_data_availability: data_availability
	} = get(data, "definitions.frontmatter") || {}
  return {
		innovation_strategy,
		policy_areas,
		innovation_roles,
		innovation_terms,
		innovation_activities,
		data_availability
	}
}

export default useDefinitions
