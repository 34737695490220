import styled from 'styled-components'

const Tag = styled.div`
  display: inline-block;
  width: auto;
  max-width: 100%;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 9px 1.25rem;
  border-radius: 40px;
  border: 2px solid ${({ theme }) => theme.grey1};
  color: $grey-1;
  font-family: ${({ theme }) => theme.fonts["roboto"]};
  font-size: 0.875rem;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-decoration: none;
  user-select: none;

  @media (min-width: ${({ theme }) => theme.mobile}) {
    text-align: center;
  }

  &:first-child {
    margin-left: 0;
  }

  &.purple, &.policy {
    color: ${({ theme }) => theme.purple};
    border-color: ${({ theme }) => theme.purple};
  }
  &.green-blue, &.skill {
    color: ${({ theme }) => theme.greenBlue};
    border-color: ${({ theme }) => theme.greenBlue};
  }
  &.dark-blue, &.activity, &.term {
    color: ${({ theme }) => theme.darkBlue};
    border-color: ${({ theme }) => theme.darkBlue};
  }
  &.light-blue, &.funding_source {
    color: ${({ theme }) => theme.lightBlue};
    border-color: ${({ theme }) => theme.lightBlue};
  }
  &.pink {
    color: ${({ theme }) => theme.pink};
    border-color: ${({ theme }) => theme.pink};
  }

  span {
    white-space: normal;
    line-height: 1.33;
  }
`

export default Tag
