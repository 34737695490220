import React, { forwardRef } from "react"
import styled from "styled-components"
import filter from "lodash/filter"
import map from "lodash/map"

const SummaryCard = ({
  title,
  slug,
  display_name,
  country_name,
  mayors,
  alt_mayor_title,
  officers,
  population,
  census_year,
  websites,
  city_link,
  download_link,
  download_filename,
  benefits,
  benefits_other,
  successes,
  successes_other
}, ref) => {
  websites = filter(websites, site => site !== '')
  return (
    <SummaryCardWrapper className="summary-card" ref={ref}>
      <div className="summary-card__upper">
        <div className="summary-card__inner">
          <div className="stats">
            {/* TITLE */}
            <h1 className="summary-card__title">{display_name || title}</h1>

            {/* COUNTRY */}
            {country_name && (
              <p className="summary-card__country">{country_name}</p>
            )}

            {(mayors || population) && (
              <div className="summary-card__stats">
                {/* MAYOR */}
                {mayors.length > 0 && (
                  <div className="summary-card__stats__item mayors">
                    <div className="summary-card__stats__item__title">
                      {alt_mayor_title ? alt_mayor_title : "Mayor"}{mayors.length > 1 && 's'}
                    </div>
                    {map(mayors, (name, i) => (
                      <p key={`mayor-${i}`}>{name}</p>
                    ))}
                  </div>
                )}
                {/* POPULATION */}
                {population && (
                  <div className="summary-card__stats__item">
                    <div className="summary-card__stats__item__title">
                      Population
                  </div>
                    <p>
                      {population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{census_year && census_year !==0 && ` (${census_year})`}
                    </p>
                  </div>
                )}
              </div>
            )}

            {websites && websites.length > 0 && (
              <div className="summary-card__stats">
                {/* WEBSITE */}
                <div className="summary-card__stats__item">
                  <div className="summary-card__stats__item__title">
                    Innovation Website{websites.length > 1 && 's'}
                  </div>
                  {map(websites, (url = '', i) => (
                    <div key={`website-${i}`} style={{ position: 'relative', width: '100%', height: 23 }}>
                      <div style={{ position: 'absolute', width: '100%', height: 'auto' }}>
                        <a
                          target="_blank"
                          href={url}
                          rel="noopener noreferrer"
                          title={url}
                        >
                          {url.replace(/(^\w+:|^)\/\//, '')}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* OFFICERS */}
            {officers && officers.length > 0 && (
              <div className="summary-card__stats">
                <div className="summary-card__stats__item">
                  <div className="summary-card__stats__item__title">
                    Lead Innovation Officer{officers.length > 1 && 's'}
                  </div>
                  <p style={{ whiteSpace: "pre-wrap"}}>
                  {map(officers, (name, i) => (
                    <span key={`officer-${i}`}>{i !== 0 && ', '}{name}</span>
                  ))}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="actions">
            {(city_link || download_link) && (
              <div className="summary-card__actions">
                {/* CONTACT */}
                {city_link && (
                  <a
                    href={`mailto:${city_link}`}
                    className="button primary"
                    title={`Contact Link for ${title}`}
                  >
                    Contact city
                </a>
                )}

                {/* DOWNLOAD */}
                {download_link && download_filename && (
                  <a
                    href={download_filename || `/assets/${slug}.pdf`}
                    className="button"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={`Download Link for ${title}`}
                  >
                    Download
                </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* BENFITS AND SUCCESSES */}
      {(benefits || successes || benefits_other || successes_other) && (
        <div className="summary-card__lower">
          <div className="summary-card__inner">
            <div className="content">

              {/* BENFITS */}
              {(benefits || benefits_other) && (
                <>
                  <div className="title">Innovation is helping to:</div>
                  <ul>
                    {benefits && map(benefits, (text, i) => text === "" ? null : (
                      <li key={`benefit-${i}`}>
                        <p>{text}</p>
                      </li>
                    ))}
                    {benefits_other && map(benefits_other, (text, i) => text === "" ? null : (
                      <li key={`benefit-other-${i}`}>
                        <p>{text}</p>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {/* SUCCESSES */}
              {(successes || successes_other) && (
                <>
                  <div className="title">Critical success factors:</div>
                  <ul>
                    {successes && successes.map((text, i) => text === "" ? null : (
                      <li key={`success-${i}`}>
                        <p>{text}</p>
                      </li>
                    ))}
                    {successes_other && successes_other.map((text, i) => text === "" ? null : (
                      <li key={`success-${i}`}>
                        <p>{text}</p>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      )}

    </SummaryCardWrapper>
  )
}

const SummaryCardWrapper = styled.div`
  &, .summary-card {
    display: block;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.06);
    overflow: hidden;

    &:hover, &:focus, &:active {
      box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.10);
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
      box-shadow: none;
      border-radius: 0;
      margin-left: -20px;
      margin-right: -20px;
    }

    &__inner {
      padding: 1.75rem 1.25rem;

      @media (min-width: ${({ theme }) => theme.largeDesktop}) {
        padding: 2.5rem;
      }
    }

    &__title {
      margin-bottom: 0.25rem;
    }

    &__country {
      font-size: 1.375rem;
      font-family: ${({ theme }) => theme.fonts["vollkorn"]};
      line-height: 1.45;
      color: ${({ theme }) => theme.grey1};
      margin-bottom: 0.5rem;
    }

    &__stats {
      display: flex;
      margin-bottom: 0.75rem;

      @media (min-width: ${({ theme }) => theme.mobile}) {
        margin-bottom: 1rem;
      }

      &__item {
        flex: 1;
        overflow: hidden;
        padding-right: 0.25rem;

        &__title {
          font-family: ${({ theme }) => theme.fonts["roboto"]};
          font-size: 1;
          line-height: 1.5;
          letter-spacing: 0.45px;
          font-weight: bold;
          color: ${({ theme }) => theme.grey1};
          margin-bottom: 0.125rem;

          @media (min-width: ${({ theme }) => theme.mobile}) {
            font-size: 1.125rem;
            line-height: 1.33;
          }
        }

        p, a {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &.mayors {
          p {
            display: block;
            white-space: pre-wrap;
          }
          p + p {
            line-height: 1;
          }
        }

        a {
          display: block;
          font-size: 0.875rem;
          font-family: ${({ theme }) => theme.fonts["roboto"]};
          line-height: normal;
          letter-spacing: 0.35px;
          color: ${({ theme }) => theme.darkBlue};
          text-decoration: underline;
          font-weight: bold;
          &:hover {
            color: ${({ theme }) => theme.lightBlue};
          }
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;

      .button {
        flex-basis: calc(50% - 0.75rem);

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__upper {
      display: flex;
      flex-direction: column;
      height: auto;
      @media (min-width: ${({ theme }) => theme.tablet}) {
        min-height: 25rem;
      }

      .summary-card__inner {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .stats {
          flex: 1;
        }

        body.is-ie & {
          display: block;
        }
      }

    }

    &__lower {
      h5, .title {
        ${({ theme }) => theme.isHeadingSize['5']}
        margin-bottom: 0.625rem;
        color: ${({ theme }) => theme.grey1};
      }
      .summary-card__inner {
        padding-top: 0;

        ul {
          margin: 1rem;
        }
      }
    }
  }
`

export default forwardRef(SummaryCard)
