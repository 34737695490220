import styled from "styled-components"

const QuestionIcon = styled.span`
  position: relative;
  display: inline-block;
  width: 13px;
  height: 13px;
  background: #dddee0;
  border-radius: 6.5px;
  font-size: 10px;
  margin-left: 0.625rem;
  cursor: pointer;
  vertical-align: middle;
  color: ${({ theme }) => theme.grey1};
  
  &:after {
    position: absolute;
    display: inline-block;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    content: "?";
  }
`

export default QuestionIcon;
