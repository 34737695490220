import { graphql, useStaticQuery } from 'gatsby'
import keyBy from "lodash/keyBy"

const useFundingSources = () => {
  const data = useStaticQuery(graphql`
    query {
      funding_sources: allMarkdownRemark(
        filter: { 
          frontmatter: {
            tag_type: { 
              eq: "funding_source"
            }
          }
        }
      ) {
        nodes {
          id
          frontmatter {
            title
            display_name
            description
            examples
          }
        }
      }
    }
  `)

  const funding_sources_list = data.funding_sources.nodes.map(funding_source => ({
    id: funding_source.id,
    ...funding_source.frontmatter,
  }))

  return {
    items: funding_sources_list,
    byTitle: keyBy(funding_sources_list, "title")
  }
}

export default useFundingSources
