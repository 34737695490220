import { graphql, useStaticQuery } from 'gatsby'
import useCities from "./use-cities"
import reduce from "lodash/reduce"
import get from "lodash/get"
import mapValues from "lodash/mapValues"
import keyBy from "lodash/keyBy"

const useFundedActivities = () => {
  const cities = useCities()
  const data = useStaticQuery(graphql`
    query {
      funded_activities: allMarkdownRemark(
        filter: { 
          frontmatter: {
            tag_type: { 
              eq: "funded_activity"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        nodes {
          id
          frontmatter {
            title
            display_name
            description
            examples
            order
          }
        }
      }
    }
  `)

  const funded_activities_list = data.funded_activities.nodes.map(funded_activity => ({
    id: funded_activity.id,
    ...funded_activity.frontmatter,
  }))

  let total = 0
  let percentages = reduce(cities, (acc, city) => {
    const funded_activities = get(city, "section_funded_activities.funded_activities") || []
    funded_activities.forEach(activity => {
      if(!acc[activity]) {
        acc[activity] = 1
      } else {
        acc[activity] += 1
      }
      total += 1
    })
    return acc
  }, {})

  percentages = mapValues(percentages, item => ({
    percent: Math.round((item / total) * 100),
    total: item
  }))

  return {
    items: funded_activities_list,
    byTitle: keyBy(funded_activities_list, "title"),
    percentages,
    cityCount: total
  }
}

export default useFundedActivities
