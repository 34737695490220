import { graphql, useStaticQuery } from 'gatsby'
import keyBy from "lodash/keyBy"

const usePolicies = () => {
  const data = useStaticQuery(graphql`
    query {
      policies: allMarkdownRemark(
        filter: { 
          frontmatter: {
            tag_type: { 
              eq: "policy"
            }
          }
        }
      ) {
        nodes {
          id
          frontmatter {
            title
            display_name
            description
            examples
          }
        }
      }
    }
  `)

  const policies_list = data.policies.nodes.map(policy => ({
    id: policy.id,
    ...policy.frontmatter,
  }))

  return {
    items: policies_list,
    byTitle: keyBy(policies_list, "title")
  }
}

export default usePolicies
