import { graphql, useStaticQuery } from 'gatsby'
import keyBy from "lodash/keyBy"

const useSkills = () => {
  const data = useStaticQuery(graphql`
    query {
      skills: allMarkdownRemark(
        filter: { 
          frontmatter: {
            tag_type: { 
              eq: "skill"
            }
          }
        }
      ) {
        nodes {
          id
          frontmatter {
            title
            display_name
            description
            examples
          }
        }
      }
    }
  `)

  const skills_list = data.skills.nodes.map(skill => ({
    id: skill.id,
    ...skill.frontmatter,
  }))

  return {
    items: skills_list,
    byTitle: keyBy(skills_list, "title")
  }
}

export default useSkills
