import React from "react"
import Tag from "./styled-components/Tag"
import QuestionIcon from "./styled-components/QuestionIcon"
import ReactTooltip from "react-tooltip"

const TagWithTooltip = ({
  id,
  description,
  examples,
  comment,
  isPreview,
  children,
  ...props
}) => {
  const hasTooltip = comment || description || examples
  return (
    <>
      <Tag {...props} id={id}>
        <span>
          {children}
          {hasTooltip && <QuestionIcon data-tip data-for={`${id}-tooltip`}/>}
        </span>
      </Tag>
      {hasTooltip && !isPreview && (
        <ReactTooltip
          id={`${id}-tooltip`}
          type="light"
          getContent={() => (
            <>
              {comment && <div className="text">{comment}</div>}
              {description && (
                <>
                  <div className="title">{children}</div>
                  <div className="text">{description}</div>
                </>
              )}
              {examples && <div className="text">e.g. {examples}</div>}
            </>
          )}
        />
      )}
    </>
  )
}

export default TagWithTooltip
